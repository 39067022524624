<template>
  <div class="page page-How">
    <template v-for="(step, index) in this.$t('lang.how')">
      <transition
        :enter-active-class="$store.state.ui.enterClassName"
        :leave-active-class="$store.state.ui.leaveClassName"
        :key="step.id"
      >
        <div
          class="page"
          v-show="step.id === showStep"
          key="step.id"
          :class="{ loaded: isSubPageLoaded }"
        >
          <div class="number">{{ step.number }}</div>
          <div class="app-screen m25" :style="imgs[index].background2"></div>
          <div
            class="app-screen m50"
            :style="imgs[index].background1"
            v-if="!isMobile"
          ></div>
          <div class="steps-page">
            <div class="title m50" :class="step.titleClass">
              {{ step.title }}
            </div>
            <div class="text m100" v-html="step.text"></div>
          </div>
        </div>
      </transition>
    </template>

    <div :class="{ loaded: isLoaded }">
      <div class="navigation m100">
        <div class="pages" v-if="isMobile">
          {{ subPageIndex }}/{{ subPagesCount }}
        </div>
        <div
          class="prev link"
          :class="{ disable: showStep === firstPage }"
          @click="changePage('prev')"
        ></div>
        <div
          class="next link"
          :class="{ disable: showStep === lastPage }"
          @click="changePage('next')"
        ></div>
      </div>

      <download v-if="isMobile" class="m100"></download>
    </div>
  </div>
</template>

<style scoped>
.page,
.m25,
.m50,
.m100 {
  will-change: transform;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
}
</style>

<script>
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';
import Download from './includes/Download';
import ENV from '@/environment';

export default {
  name: 'How',
  mixins: [loader, isMobile, functions],
  components: { Download },

  data() {
    return {
      pageData: {
        page: 'How',
        isDownloadLeft: true,
        isMobileFooterHidden: true,
      },
      showStep: 1,
      subPageIndex: 1,
      steps: this.$t('lang.how'),
      imgs: [
        {
          backgroundImages1: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone01@2x.png'
                : '/img/phones/phone01@2x.png',
          },
          backgroundImages2: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone01-2@2x.png'
                : '/img/phones/phone01-2@2x.png',
          },
        },
        {
          backgroundImages1: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone02@2x.png'
                : '/img/phones/phone02@2x.png',
          },
          backgroundImages2: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone02-2@2x.png'
                : '/img/phones/phone02-2@2x.png',
          },
        },
        {
          backgroundImages1: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone03@2x.png'
                : '/img/phones/phone03@2x.png',
          },
          backgroundImages2: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone03-2@2x.png'
                : '/img/phones/phone03-2@2x.png',
          },
        },
        {
          backgroundImages1: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone04@2x.png'
                : '/img/phones/phone04@2x.png',
          },
          backgroundImages2: {
            '2x':
              ENV.REGION === 'by'
                ? '/img/by/phones/phone04-2@2x.png'
                : '/img/phones/phone04-2@2x.png',
          },
        },
      ],
      ENV,
    };
  },

  deactivated() {
    this.isLoaded = false;
    this.subPageIndex = 1;
    this.showStep = 1;
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },

  computed: {
    lastPage() {
      return (
        this.steps[this.steps.length - 1] &&
        this.steps[this.steps.length - 1].id
      );
    },
    firstPage() {
      return this.steps[0] && this.steps[0].id;
    },
    subPagesCount() {
      return this.steps.length;
    },
  },

  watch: {
    showStep(val) {
      this.watchStep(val);
    },
  },

  methods: {
    onActivated() {
      if (this.imagesLoaded) {
        this.watchStep();
        this.pageLoaded();
      } else {
        const images = [];

        for (const i in this.steps) {
          this.imgs[i].background1 = this.bgImgStyle(
            this.imgs[i].backgroundImages1,
          );
          this.imgs[i].background2 = this.bgImgStyle(
            this.imgs[i].backgroundImages2,
          );

          for (const j in this.imgs[i].backgroundImages1) {
            images.push(this.imgs[i].backgroundImages1[j]);
          }
          for (const j in this.imgs[i].backgroundImages2) {
            images.push(this.imgs[i].backgroundImages2[j]);
          }
        }

        this.loadImages(images);
      }
    },
    watchStep(val) {
      this.isSubPageLoaded = false;

      setTimeout(() => {
        this.isSubPageLoaded = true;
      }, 100);
    },
    changePage(delta) {
      for (const i in this.steps) {
        if (delta === 'prev') {
          if (
            this.steps[i * 1 + 1] &&
            this.steps[i * 1 + 1].id === this.showStep
          ) {
            this.showStep = this.steps[i].id;
            this.subPageIndex--;
            break;
          }
        } else {
          if (
            this.steps[i * 1 - 1] &&
            this.steps[i * 1 - 1].id === this.showStep
          ) {
            this.showStep = this.steps[i].id;
            this.subPageIndex++;
            break;
          }
        }
      }
    },
  },
};
</script>
